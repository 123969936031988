import { Grid, PaletteMode, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import { sxs } from '@utils/mui/sx';

const getSearchBoxStyles = (colorVersion: string, isDisable: boolean): SxProps => {
    let backgroundColor: string;
    let boxShadow: string | number;
    let opacity: number;
    let pointerEvents: string;

    if (isDisable) {
        backgroundColor = 'grey.300';
        boxShadow = 'none';
        opacity = 0.5;
        pointerEvents = 'none';
    } else {
        backgroundColor = colorVersion === 'dark' ? 'secondary.main' : 'background.default';
        boxShadow = 1;
        opacity = 1;
        pointerEvents = 'auto';
    }

    return {
        backgroundColor,
        border: 1,
        borderColor: colorVersion === 'dark' ? 'grey.300' : 'transparent',
        borderRadius: 0.5,
        boxShadow,
        height: 56,
        opacity,
        pointerEvents,
        position: 'relative',
    };
};

interface SearchBoxContainerProps {
    children: ReactNode;
    colorVersion?: PaletteMode;
    isDisable?: boolean;
    sx?: SxProps;
}

const SearchBoxContainer: FC<SearchBoxContainerProps> = ({
    children,
    colorVersion = 'light',
    isDisable = false,
    sx = {},
}) => {
    const searchBoxStyles = getSearchBoxStyles(colorVersion, isDisable);

    return (
        <Grid
            alignContent="center"
            alignItems="center"
            direction="row"
            id="keep-underbar-card-open"
            sx={sxs([searchBoxStyles, sx])}
            container
        >
            {children}
        </Grid>
    );
};

export default SearchBoxContainer;
