import { Grid, Paper, SxProps } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import HeaderBar from '@components/HeaderBar';

const contentSx: SxProps = {
    '&.MuiGrid-root': {
        maxWidth: 800,
        mx: 'auto',
        my: { sm: -35, xs: -29 },
        p: { sm: 5, xs: 2 },
    },
};

const paperSx: SxProps = {
    bgcolor: 'common.white',
    px: { sm: 5, xs: 2 },
    py: { sm: 5, xs: 3 },
};

const ConnexionFlowLayout: FC<PropsWithChildren> = ({ children }) => (
    <Grid
        sx={{
            '&.MuiGrid-root': {
                maxWidth: '100%',
            },
        }}
        container
    >
        <HeaderBar sx={{ height: 250 }} />
        <Grid sm={9} sx={contentSx} xs={12} item>
            <Paper elevation={3} sx={paperSx}>
                {children}
            </Paper>
        </Grid>
    </Grid>
);

export default ConnexionFlowLayout;
