import { LawTheme } from '@livv/models';

export const TYPOS_FR = ['Livre', 'Partie', 'Titre', 'Chapitre', 'Section'];

export const TYPOS_EN = ['Book', 'Part', 'Title', 'Chapter', 'Section'];

export const THEME_ORDER: LawTheme[] = [
    'commercial-law',
    'economic-law',
    'procedural-law',
    'practical-works',
    'other',
];
