import { Languages, LawTheme, TreeWithId } from '@livv/models';
import { Query, query, where } from 'firebase/firestore';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { treeCollection } from '../collections';
import { getDefaultLanguages } from '@utils/language';

const useKeywordTrees = (
    languages?: Languages[],
    theme?: LawTheme | undefined,
): [TreeWithId[] | undefined, boolean, Error | undefined] => {
    const queryConstraints = [
        where('isPublished', '==', true),
        where('isKeyword', '==', true),
        where('language', 'in', getDefaultLanguages(languages)),
    ];
    if (theme) {
        queryConstraints.push(where('theme', '==', theme));
    }
    const keywordTreesQuery = query(treeCollection, ...queryConstraints) as Query<TreeWithId>;

    const [books, loading, error] = useCollectionDataOnce<TreeWithId>(keywordTreesQuery);

    return [books, loading, error];
};

export default useKeywordTrees;
