import { LanguageUppercase, LawTheme, TreeWithId } from '@livv/models';
import { Card, CardContent, List, ListItem, SxProps, Typography } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import { PlainLink } from '@components/Links';
import { sortTreesByLanguageAndTitle } from '@utils/trees';

const cardSx: Record<string, SxProps> = {
    content: {
        alignItems: 'start',
        display: 'flex',
        flexDirection: 'column',
        gap: 1,
        height: '100%',
        p: 3,
    },
    root: {
        '&:hover': {
            background:
                'radial-gradient(445.27% 109.99% at 80% 80%, rgba(196, 168, 107, 0.20) 0%, rgba(255, 255, 255, 0.08) 99.48%)',
        },
        background:
            'radial-gradient(445.27% 109.99% at 95.37% 90.37%, rgba(196, 168, 107, 0.20) 0%, rgba(255, 255, 255, 0.08) 99.48%)',
        border: '1px solid',
        borderColor: 'grey.300',
        borderRadius: '6px',
        height: '100%',
        transition: 'background 0.3s ease-in-out',
    },
};

interface LawThemeCardProps {
    books?: TreeWithId[];
    lawTheme: LawTheme;
}

export const LawThemeCard: FC<LawThemeCardProps> = ({ books, lawTheme }) => {
    const { lang, t } = useTranslation('books');
    const sortedBooks = sortTreesByLanguageAndTitle(LanguageUppercase.FR, books, lang);

    return (
        <Card component="section" elevation={0} sx={cardSx.root}>
            <CardContent sx={cardSx.content}>
                <Typography sx={{ color: 'primary.main', fontWeight: 600 }} variant="body1">
                    {t(`theme.${lawTheme}`)}
                </Typography>
                {sortedBooks && (
                    <List sx={{ color: 'white', listStyleType: 'disc', py: 0 }}>
                        {sortedBooks.map((book) => (
                            <ListItem
                                key={book.id}
                                sx={{ display: 'list-item', ml: 2 }}
                                disablePadding
                            >
                                <PlainLink
                                    data-test-id={`law-theme-card-${lawTheme}-tree-id-${book.id}`}
                                    href={`/search/tree-view?bookId=${book.id}`}
                                    rel="nofollow"
                                    sx={{
                                        color: 'white',
                                        fontSize: 14,
                                        fontWeight: 400,
                                        lineHeight: 2,
                                    }}
                                >
                                    {book.title}
                                </PlainLink>
                            </ListItem>
                        ))}
                    </List>
                )}
            </CardContent>
        </Card>
    );
};
