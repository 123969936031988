import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import { Box, IconButton, InputBase, PaletteMode, SxProps, Theme, useTheme } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import { FC } from 'react';
import { searchButtonSx, searchInputBaseSx } from '@components/search/algolia/SearchBox/index';
import { sxs } from '@utils/mui/sx';

const infoIconSx: (colorVersion: string, theme: Theme) => SxProps = (colorVersion, theme) => ({
    alignSelf: 'center',
    color: colorVersion === 'dark' ? theme.palette.grey[500] : theme.palette.grey[600],
    ml: 2,
    mr: 1,
});

const inputBaseDisabledSx: (colorVersion: string, theme: Theme) => SxProps = (
    colorVersion,
    theme,
) => ({
    '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor:
            colorVersion === 'dark' ? theme.palette.common.white : theme.palette.grey[100],
    },
});

interface AlgoliaFakeSearchBoxProps {
    colorVersion?: PaletteMode;
    placeholder?: string;
}

const AlgoliaLoadingSearchBox: FC<AlgoliaFakeSearchBoxProps> = ({
    colorVersion = 'light',
    placeholder,
}) => {
    const { t } = useTranslation('common');
    const theme = useTheme();

    return (
        <Box sx={{ alignItems: 'center', display: 'flex', width: '100%' }}>
            <InputBase
                placeholder={placeholder || t('algoliaSearchBox.loading')}
                startAdornment={<InfoIcon sx={infoIconSx(colorVersion, theme)} />}
                sx={sxs([
                    searchInputBaseSx(colorVersion),
                    inputBaseDisabledSx(colorVersion, theme),
                ])}
                disabled
            />
            <Box sx={{ float: 'right', ml: 'auto', mr: 1 }}>
                <IconButton sx={searchButtonSx}>
                    <SearchIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default AlgoliaLoadingSearchBox;
