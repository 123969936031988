import { User } from '@livv/models';
import { Timestamp, doc, updateDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { usersCollection } from '@utils/collections';
import useUser from '@utils/context/user';

interface UserSessionInterface {
    createSession: (userId: string) => Promise<void>;
    isLoading: boolean;
    isSessionValid: boolean;
}

const useUserSession = (): UserSessionInterface => {
    const [isSessionValid, setIsSessionValid] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const { info, privileges, loading } = useUser();

    useEffect(() => {
        if (!loading && privileges && info) {
            if (privileges.skipSessionVerification || privileges.role === 'admin') {
                setIsSessionValid(true);
            } else {
                const currentSessionId = info?.session?.sessionId;
                const localStorageSessionId = window.localStorage.getItem('sessionId');

                setIsSessionValid(!currentSessionId || currentSessionId === localStorageSessionId);
            }
        }
    }, [loading, privileges, info]);

    const createSession = async (userId: string) => {
        setIsLoading(true);
        const newSessionId = v4();
        window.localStorage.setItem('sessionId', newSessionId);
        await updateDoc(doc(usersCollection, userId), {
            session: { createdAt: Timestamp.now(), sessionId: newSessionId },
        } as Pick<User, 'session'>);
        setIsLoading(false);
    };

    return { createSession, isLoading, isSessionValid };
};

export default useUserSession;
