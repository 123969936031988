import { Typography, Skeleton, Box, SxProps } from '@mui/material';
import { FC } from 'react';

const getWidth = (index: number, lastIndex: number) => {
    switch (true) {
        case index === lastIndex:
            return '40%';
        case index % 2 === 0:
            return '95%';
        default:
            return '90%';
    }
};
interface FullTextSkeletonProps {
    lines: number;
    sx?: SxProps;
    withHeader?: boolean;
}

const FullTextSkeleton: FC<FullTextSkeletonProps> = ({ lines, sx, withHeader = false }) => {
    const indexes = Array.from(Array(lines).keys()); // 0, 1, 2 ... lines

    return (
        <Box sx={sx}>
            {withHeader && (
                <Typography variant="h1" gutterBottom>
                    <Skeleton width="50%" />
                </Typography>
            )}
            <Typography>
                {indexes.map((index) => (
                    <Skeleton
                        key={`full-text-skeleton-line-${index + 1}`}
                        width={getWidth(index, indexes.length - 1)}
                    />
                ))}
            </Typography>
        </Box>
    );
};

export default FullTextSkeleton;
