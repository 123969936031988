import { Box, Container, Grid, SxProps, Typography, useTheme } from '@mui/material';
import { NextPage } from 'next';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { SearchBoxContainer } from '@components/search';
import AlgoliaSearchBox from '@components/search/algolia/SearchBox';
import AlgoliaLoadingSearchBox from '@components/search/algolia/SearchBox/AlgoliaLoadingSearchBox';
import InstantSearch from '@components/search/InstantSearch';
import { LawThemesContainer } from '@screens/Home/LawThemesContainer';
import { INDEX_NAME } from '@utils/consts/search';
import { PageProps } from '@utils/types/common';

const backgroundOverlaySx: SxProps = {
    height: '100vh',
    left: 0,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100vw',
    zIndex: -1,
};

const backgroundSx: SxProps = {
    '@keyframes zoom': {
        '0%': {
            transform: 'scale(1)',
        },
        '100%': {
            transform: 'scale(1.1)',
        },
    },
    animation: 'zoom 10s ease-out both',
    backgroundImage: { tablet: 'url("/assets/home-bg-image.webp")', xs: null },
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    bgcolor: 'grey.100',
    height: '100%',
    width: '100%',
};

const containerSx: SxProps = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    mt: 11,
};

const searchBoxContainerSx: SxProps = {
    maxWidth: '900px',
    mt: 3,
    width: 0.9,
};

const HomePage: NextPage<PageProps> = () => {
    const { t } = useTranslation('home');
    const router = useRouter();
    const theme = useTheme();
    const [loading, setLoading] = useState(true);

    return (
        <Box>
            <NextHead>
                <title>{t('meta.title')}</title>
                <meta content={t('meta.description')} name="description" />
                {/* used for search console site validation */}
                <meta
                    content="FL4OjVF_ktNlx3-eai0Tuj7jjU2Oa728w-6wRFENR3k"
                    name="google-site-verification"
                />
            </NextHead>
            <Box sx={backgroundOverlaySx}>
                <Box sx={backgroundSx} />
            </Box>
            <Grid alignItems="center" data-test-id="home" direction="column" container>
                <Container maxWidth="lg" sx={containerSx}>
                    <Typography
                        color={theme.palette.grey[700]}
                        data-test-id="test-home-title"
                        maxWidth={theme.spacing(110)}
                        textAlign="center"
                        variant="h1"
                        gutterBottom
                    >
                        {t('title')}
                    </Typography>
                    {loading && (
                        <SearchBoxContainer colorVersion="dark" sx={searchBoxContainerSx} isDisable>
                            <AlgoliaLoadingSearchBox colorVersion="dark" />
                        </SearchBoxContainer>
                    )}
                    {/* Add disableRouting prop to avoid the search box to update the url, because the
                    LawThemesContainer will generate cards with links to the thematic plan */}
                    <InstantSearch onLoadingChange={setLoading} disableRouting>
                        <SearchBoxContainer colorVersion="dark" sx={searchBoxContainerSx}>
                            <AlgoliaSearchBox
                                colorVersion="dark"
                                onSearchClick={(searchValue) => {
                                    router.push(`/search?${INDEX_NAME}[query]=${searchValue}`);
                                }}
                                searchAsYouType={false}
                                withHints
                                withSubmitButton
                                withUnderbarCard
                            />
                        </SearchBoxContainer>
                    </InstantSearch>
                    <LawThemesContainer />
                </Container>
            </Grid>
        </Box>
    );
};

export default HomePage;
