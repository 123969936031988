import { Box, Fade } from '@mui/material';
import { FC } from 'react';

interface FullPageLoaderProps {
    embedded?: boolean;
    height?: string;
}

const FullPageLoader: FC<FullPageLoaderProps> = ({ embedded, height }) => (
    <Box
        sx={{
            alignItems: 'center',
            display: 'flex',
            height: height || (embedded ? '50vh' : '100vh'),
            justifyContent: 'center',
            width: embedded ? '100%' : '100vw',
        }}
    >
        <Fade timeout={{ enter: 3000 }} in>
            <img alt="Loading" src="/assets/Loader.svg" style={{ height: 'auto', width: 240 }} />
        </Fade>
    </Box>
);
export default FullPageLoader;
